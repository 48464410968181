import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './button-link.module.scss';

const ButtonLink = ({ className, to, children }) => {
  const classname = clsx(styles.button, className || {});

  return (
    <Link className={classname} to={to}>
      {children}
    </Link>
  );
};

ButtonLink.defaultProps = {
  className: '',
  to: '/',
};

ButtonLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ButtonLink;
