import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import clsx from 'clsx';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ButtonLink from '../components/button-link';
import { pageLinks } from '../helpers/navigation';

import styles from '../styles/pages/sign-up-2.module.scss';

const strings = {
  continue: 'Continue',
  subTitle: 'Ready to start promoting brands with us?',
  title: 'Here’s a quick overview:',
};

const query = graphql`
  query {
    people: file(relativePath: { eq: "sign-up-2/people.png" }) {
      childImageSharp {
        fixed(width: 61, height: 48) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    list: file(relativePath: { eq: "sign-up-2/list.png" }) {
      childImageSharp {
        fixed(width: 48, height: 48) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    device: file(relativePath: { eq: "sign-up-2/device.png" }) {
      childImageSharp {
        fixed(width: 59, height: 48) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    target: file(relativePath: { eq: "sign-up-2/target.png" }) {
      childImageSharp {
        fixed(width: 56, height: 34) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pageImg: file(relativePath: { eq: "sign-up-2/sign-up-2-img.png" }) {
      childImageSharp {
        fixed(width: 1200, height: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pageImgFluid: file(relativePath: { eq: "sign-up-2/sign-up-2-img.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const SignUpTwoPage = () => {
  const data = useStaticQuery(query);

  const people = data?.people.childImageSharp.fixed;
  const list = data?.list.childImageSharp.fixed;
  const device = data?.device.childImageSharp.fixed;
  const target = data?.target.childImageSharp.fixed;
  const pageImgFluid = data?.pageImgFluid.childImageSharp.fluid;

  const pageConfig = [
    {
      img: () => <Img fixed={people} />,
      title: 'Learn what makes a successful publisher',
      description: 'Explore the do’s and don’ts to guarantee you’re always on the right track.',
    },
    {
      img: () => <Img fixed={list} />,
      title: 'Follow the system',
      description: 'This is the most important part. All you have to do is follow the system.',
    },
    {
      img: () => <Img fixed={target} />,
      title: 'Familiarize yourself with the earning opportunity',
      description:
        'Our Compensation Plan is among the best, most powerful in the industry. It was created with your success in mind. Discover how our Compensation Plan works. Choose your goal, and go for.',
    },
    {
      img: () => <Img fixed={device} />,
      title: 'Sell with confidence',
      description:
        "We have a thriving community of esteemed, professional sellers. As the world evolves, we have significant ideas for a bright future. Once your application gets approved, we're with you all the way.",
    },
  ];

  return (
    <Layout>
      <Seo title="Page two" />
      <div className={styles.content}>
        <div className={styles.list}>
          <p className={styles.title}>{strings.subTitle}</p>
          <p className={styles.subTitle}>{strings.title}</p>
          {pageConfig.map((item, i) => {
            const isLastItem = pageConfig.length === i + 1;
            return (
              <div
                key={`${item.title}`}
                className={clsx(styles.itemContainer, !isLastItem && styles.divider)}
              >
                <div className={styles.imgContainer}>{item?.img()}</div>

                <div>
                  <p className={styles.itemTitle}>{item.title}</p>
                  <p className={styles.description}>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.mainImg}>
          <Img fluid={pageImgFluid} />
        </div>
      </div>
      <div className={styles.btnContainer}>
        <Link className={styles.backBtn} to={pageLinks.home}>
          Back
        </Link>
        <ButtonLink to={pageLinks.preCheckout}>{strings.continue}</ButtonLink>
      </div>
    </Layout>
  );
};

export default SignUpTwoPage;
